import React from 'react'

class LazyLoadComponent extends React.Component {
  ref = React.createRef()
  image = React.createRef()
  observer = typeof window !== 'undefined' && new IntersectionObserver(
    entries => {
      if (entries[0].intersectionRatio > 0) {
        this.observer.disconnect()
        this.setState({
          isVisible: entries[0].intersectionRatio >= 0
        })
      }
    },
    {
      root: null,
      rootMargin: '50px',
      threshold: 0
    }
  )

  state = {
    isVisible: false
  }

  componentDidMount() {
    if(typeof window !== 'undefined') {
      this.observer.observe(this.ref.current)
    }
  }

  componentWillUnmount() {
    if(typeof window !== 'undefined') {
      this.observer.disconnect()
    }
  }

  render() {
    return (
      <div
        ref={this.ref}
        className={`lazyload-component`}
      >
        {this.state.isVisible ? this.props.children : null}
      </div>
       
    )
  }
}

export default LazyLoadComponent
import React from 'react'
import LazyLoadBgImage from '../LazyLoadBgImage'
import LazyLoadComponent from '../LazyLoadComponent'
import {
  Player,
  ControlBar,
  PlayToggle,
  VolumeMenuButton,
  ProgressControl
} from 'video-react'
import { LinkRenderer } from '../../../lib/utils'
import ReactMarkdown from '../../../lib/ReactMarkdown'

const ImageCaption = element => {
  return (
  <div className="row">
    <div
      className="col-lg-4 offset-lg-2"
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <LazyLoadBgImage
        className="topper-main image-caption-main"
        asset={element.image.fields}
      >
        {element.image.fields.file.contentType.split('/')[0] === 'video' && (
          <LazyLoadComponent>
            <Player
            autoPlay
            playsInline
            muted={true}
            controls
            loop
            className="topper-video"
          >
            <source
              src={element.image.fields.file.url}
              type={element.image.fields.file.contentType}
            />
            <ControlBar
              autoHide={false}
              disableDefaultControls={true}
              className="video-controls"
            >
              <ProgressControl />
              <PlayToggle />
              <div className="control-spacer"></div>
              <VolumeMenuButton />
            </ControlBar>
          </Player>
        </LazyLoadComponent>
        )}
        <div className="col-md-12 image-caption-container d-lg-none d-xl-none">
          <div className="row image-caption">
            <ReactMarkdown
              source={element.text}
              escapeHtml={false}
              renderers={{ link: LinkRenderer, text: (node) => {
                return `${node.value}`.replace(/-/g,'\u2013')
            } }}
            />
          </div>
          {element.sponsorImage && (
            <div className="row topper-sponsor">
              <div className="col-lg-2 topper-sponsor-text">
                <ReactMarkdown
                  source={element.text}
                  escapeHtml={false}
                  renderers={{ link: LinkRenderer, text: (node) => {
                    return `${node.value}`.replace(/-/g,'\u2013')
            } }}
                />
              </div>
              <LazyLoadBgImage
                className="col-lg-2 topper-sponsor-image"
                asset={element.sponsorImage.fields}
              ></LazyLoadBgImage>
            </div>
          )}
        </div>
      </LazyLoadBgImage>
    </div>
    <div className="col-lg-4 d-none d-lg-block">
      <div className="col-md-12 image-caption-container desktop-image-caption-container">
        <div className="row image-caption">
          <ReactMarkdown
            source={element.text}
            escapeHtml={false}
            renderers={{ link: LinkRenderer, text: (node) => {
              return `${node.value}`.replace(/(\S+)-(\S+)/g,'$1\u2013$2')
            } }}
          />
        </div>
        {element.sponsorImage && (
          <div className="row topper-sponsor">
            <div className="col-lg-2 topper-sponsor-text">
              <ReactMarkdown
                source={element.text}
                escapeHtml={false}
                renderers={{ link: LinkRenderer, text: (node) => {
              return `${node.value}`.replace(/(\S+)-(\S+)/g,'$1\u2013$2')
            } }}
              />
            </div>
            <LazyLoadBgImage
              className="col-lg-2 topper-sponsor-image"
              asset={element.sponsorImage.fields}
            ></LazyLoadBgImage>
          </div>
        )}
      </div>
    </div>
  </div>
)}

export default ImageCaption

import React from 'react'
import { sContext } from '../../../context'

class TagsDates extends React.PureComponent {
  render() {
    const localePath = this.context.currentLocale

    return (
      <div className="deckbylines-main">
        <div className="col-lg-12">
          <div className="tags-date-tags">
            {this.props.aggregatorTag.map(
              (tags, i) =>
                i < this.props.aggregatorTag.length - 1 ? (
                  <span key={'tags-' + i}>
                    <a
                      href={localePath + '/category/?aggTag=' + tags}
                      id="uline" >
                      {tags}
                    </a>
                  </span>
                ) :
                (i >= this.props.aggregatorTag.length - 1 && (
                  <span key={'tags-i'}>
                    <a
                      href={localePath + '/category/?aggTag=' + tags}
                      onClick={() => {
                        if(typeof document !== 'undefined') {
                          document.location.pathname =
                            localePath + '/category/?aggTag=' + tags
                        }
                      }}
                      id="uline" >
                      {tags}
                    </a>
                  </span>
                ))
            )}
          </div>
          <div className="body-text-timestamp tags-date-text">
            {this.props.text}
          </div>
        </div>
      </div>
    )
  }
}

TagsDates.contextType = sContext

export default TagsDates

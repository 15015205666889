import React from 'react'
import { LinkRenderer } from '../../../lib/utils'

import ReactMarkdown from '../../../lib/ReactMarkdown'

const PullQuote = ({
  text,
  credit
}) => (
  <div className="mobilequote-main">
    <div>
      <div className={'row mobilequote-text'}>
        <ReactMarkdown
          source={text}
          escapeHtml={false}
          renderers={{ link: LinkRenderer }}
        />
      </div>
      <div
        className="row leadintext-divider"
        style={credit ? { height: '50px' } : { display: 'none' }} />
      <div className={'row mobilequote-credits'}>
        <ReactMarkdown
          source={credit}
          escapeHtml={false}
          renderers={{ link: LinkRenderer }}
        />
      </div>
    </div>
  </div>
)

export default PullQuote

import React from 'react'
import { LinkRenderer } from '../../../lib/utils'

import ReactMarkdown from '../../../lib/ReactMarkdown'

class BodyText extends React.PureComponent {
  render() {
    return (
      <div className="mobilebody-main">
        <div className="col-lg-6 offset-lg-3">
          <div className="row mobilebody-text">
            <ReactMarkdown
              source={this.props.text}
              escapeHtml={false}
              renderers={{ link: LinkRenderer }}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default BodyText

import React, { useContext } from 'react'

import Navigation from './components/Navigation'
import Footer from './components/Footer'

import Topper from './components/MobileArticle/Topper'
import DeckBylines from './components/MobileArticle/DeckBylines'
import BodyText from './components/MobileArticle/BodyText'
import PullQuote from './components/MobileArticle/PullQuote'
import ImageCaption from './components/MobileArticle/ImageCaption'
import TagsDates from './components/MobileArticle/TagsDates'
import { sContext } from '../context'

const MobileArticle = (props) => {
  const location = useContext(sContext).location
  let {
    navigation,
    paths,
    pages: {
      navigationColor,
      navigationDefaultColor,
      sections
    },
    footer,
    searchedGuides = []
  } = props
  

  if (!sections && props.pages && props.pages.content && props.pages.content.content && props.pages.content.content.length) {
    sections = props.pages.content.content.filter(v => v && v.data && v.data.target).map(v => v.data.target)
  }
  return (
  <>
    <div className="container-fluid">
      {/* Global Navigation */}
      <Navigation
        navigationColor={navigationColor}
        default={navigationDefaultColor}
        menu={navigation}
        paths={paths} />
    </div>
    <div id="mobileArticle" className={'container-fluid'}>
      {(sections || []).map(data => {
        const { type, text, image, credit, aggregatorTag } = data.fields
        let module = type ? type : data.sys.contentType.sys.id

        switch (module) {
          case 'mobile-article-topper':
            return (
              <Topper
                text={text}
                image={data.fields.subSections[0].fields.banner}
                type={data.fields.subSections[0].fields.type}
                title={data.fields.subSections[0].fields.title}
                subtitle={data.fields.subSections[0].fields.subtitle}
                sponsorImage={image}
              />
            )

          case 'mobile-article-deck-bylines':
            return <DeckBylines text={text} credit={credit} />

          case 'mobile-article-image-caption':
            return <ImageCaption text={text} image={image} />

          case 'mobile-article-body-text':
            return <BodyText text={text} />

          case 'mobile-article-pull-quote':
            return <PullQuote text={text} credit={credit} />

          case 'mobile-article-tags-date':
            return <TagsDates aggregatorTag={aggregatorTag} text={text} />

          default:
            break
        }
        return null
      })}
      
      {/* Footer */}
      <Footer
        footer={footer}
        location={location} />
    </div>
  </>
)}

export default MobileArticle

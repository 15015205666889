import React, { useContext } from 'react'
import LazyLoadBgImage from '../LazyLoadBgImage'
import { sContext } from '../../../context'

const Topper = ({
  image: {
    fields: image
  },
  type,
  title,
  subtitle,
  sponsorImage,
  text
}) => {
  const localePath = useContext(sContext).currentLocale

  return (
    <LazyLoadBgImage
      className="row tophero-main tophero-main-multimedia"
      asset={image}
      overrides={{ w: 1400, q: 70 }}
      gradient="radial"
    >
      {image.file.contentType.split('/')[0] === 'video' && (
        <video autoPlay="true" muted={true} loop className="topper-video">
          <source
            src={image.file.url}
            type={image.file.contentType}
          />
        </video>
      )}
      <div className="col-lg-12">
        <div className="tophero-container">
          <div className="row topper-tag">
            <a href={localePath + '/category/?aggTag=' + type}>
              {type}
            </a>
          </div>
          <div className="row topper-title">
            <h1>{title} {subtitle && ' — '}</h1>
            <span className="topper-subtitle">
              {subtitle && subtitle}
            </span>
          </div>
          {sponsorImage && (
            <div className="row topper-sponsor">
              <div className="col-lg-2 topper-sponsor-text">{text}</div>
              <LazyLoadBgImage
                className="col-lg-2 topper-sponsor-image"
                asset={sponsorImage.fields}
              ></LazyLoadBgImage>
            </div>
          )}
        </div>
      </div>
    </LazyLoadBgImage>
  )
}

export default Topper

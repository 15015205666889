import React from 'react'
import { LinkRenderer } from '../../../lib/utils'

import ReactMarkdown from '../../../lib/ReactMarkdown'

const DeckBylines = ({
  text,
  credit
}) => (
  <div className="deckbylines-main">
    <div className="col-lg-8 offset-lg-2">
      <div className="row deckbylines-deck">
        <ReactMarkdown
          source={text}
          escapeHtml={false}
          renderers={{ link: LinkRenderer }}
        />
      </div>
      <div className="row deckbylines-credits">
        {credit &&
          credit
            .split(' | ')
            .map((data, i) => (i === 0 && data) || <p>{data}</p>)}
      </div>
    </div>
  </div>
)

export default DeckBylines
